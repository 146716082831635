<template>
  <div>
    <MainInfoRealEstate
      :keys="keys"
      :title="$t('property_information')"
    />
    <TableIndex
      :resource-name="resourceName"
      :singular-name="singularName"
      :filter-fields="{id : $route.params.resourceId,type: 'real_estate'}"
    />
  </div>
</template>

<script>
import MainInfoRealEstate from '@/components/MainInfoRealEstate.vue'

export default {
  components: {
    MainInfoRealEstate,
  },
  data() {
    return {
      resourceName: 'customer-views',
      singularName: 'customer-views',
      details: null,
      keys: null,
    }
  },
  created() {
    if (this.$route.params.resourceId) {
      this.axios.get(`/real-estate/${this.$route.params.resourceId}`)
        .then(res => {
          this.details = res.data.data
          this.keys = _.pick(this.details, [
            'title',
            'region',
            'city',
            'district',
            'the_sub_type_of_ad',
            'type',
            'advertiser_name',
            'advertiser_character',
            'advertiser_phone',
            'selling_price',
            'published_at',
            'status_value',
          ])
        })
    }
  },
}
</script>
